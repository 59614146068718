<template>
  <v-container fluid>
    <TableViewComponent
      title="Table avantages groupe"
      @addItemEvent="onItem()"
      addItemLabel="ajouter un avantage"
      itemLabel="avantage"
      itemsLabel="avantages"
      :items="zones"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cet avantage ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '8', lg: '10', md: '11', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { AdvantagesService } from "@/service/user/advantages_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableAvantages",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      zones: [],
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.advantages.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.zones = [];

        let zones = await this.service.getAll();

        zones.forEach((r) => {
          let zone = JSON.parse(JSON.stringify(r));

          //action de consultation
          zone.view = () => {
            this.$router.push(
              routes.advantages.view.root + "/" + zone.id
            );
          };

          //action de modification
          zone.edit = () => {
            this.$router.push(
              routes.advantages.edit.root + "/" + zone.id
            );
          };

          //action de suppression
          zone.delete = async () => {
            try {
              await this.service.delete(zone.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'avantage : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.zones.push(zone);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
          width: "300px",
          default: true,
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditUser];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditUser, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadUser];
    },
  },
  mounted() {
    this.service = new AdvantagesService(this.$api.getAdvantagesApi());

    this.load();
  },
};
</script>

<style>
</style>